import {
  computed,
  watch,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeUnmount
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { fnModal } from '@/components/common/modal/index.js'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    onDepth1Item: computed(() => getters['nav/getOnDepth1Item']),
    categoryList: computed(() => getters['nav/getCategoryList']),
    category: [],
    serverDt: '',
    sellerFee: 0,
    computedSellerFee: computed(() =>
      state.params.acntTransferYn === 'N'
        ? Math.floor(state.sellerFee * state.params.dailyPrice) *
          state.params.usePeriod
        : Math.floor(state.sellerFee * state.params.dailyPrice)
    ),
    aDaySellerFee: computed(() =>
      Math.floor(Number(state.params.dailyPrice) * Number(state.sellerFee))
    ),
    amount: computed(() =>
      state.params.acntTransferYn === 'N'
        ? state.params.dailyPrice * state.params.usePeriod
        : state.params.dailyPrice
    ),
    expectedTotalAmount: computed(() => state.amount - state.computedSellerFee),
    params: {
      categoryId: '',
      recruitNum: 3,
      title: '',
      usePeriod: '',
      useDt: '',
      dailyPrice: '',
      contents: '',
      shareId: '',
      sharePw: '',
      shareYn: 'N',
      precautions: '',
      searchWord: '',
      acntTransferYn: 'N',
      autoExtension: 'N',
      unlimitedCheckYn: 'N',
      optionYn: 'N',
      reqOptionMsg: '',
      reqKakao: '0',
      reqPhone: '0',
      reqEmail: '0'
    },
    hopeDetail: computed(() => getters['party/getHopeDetail']),
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const fnCategoryList = async () => {
    const res = await proxy.$PartySvc.postPartyAddForm()
    if (res.resultCode === '0000') {
      state.category = res.category
      state.serverDt = res.serverDt
      state.sellerFee = res.sellerFee
    } else {
      alert(res.resultMsg)
    }
  }
  const fnSave = async () => {
    if (!(await isValid())) return
    const params = { ...state.params }
    if (params.recruitNum === 'acntTransfer') {
      params.recruitNum = 1
    }
    const res = await proxy.$PartySvc.postPartyAdd(params)
    if (res.resultCode === '0000') {
      alert('파티가 정상 생성되었습니다.')
      if (!proxy.$Util.isEmpty(route.query.isList) && route.query.isList > 0) {
        const keys = proxy.$_.keyBy(state.categoryList, 'categoryID')
        const depth1 = keys[proxy.$ConstCode.CATEGORY_VALUE['1000'].id] // 영상
        await dispatch('nav/updateOnDepth1Item', {
          onDepth1Item: depth1
        })
        const key2s = proxy.$_.keyBy(depth1.categoryList, 'categoryID')
        await dispatch('nav/updateOnDepth2Item', {
          onDepth2Item: key2s[params.categoryId]
        })
        await router.push({ path: '/party/list' })
      } else {
        await router.go(-1)
      }
    } else {
      alert(res.resultMsg)
    }
  }
  const { fnMyInfo, fnUpdateAuthType } = CommonFunction(state)
  const isValid = async () => {
    const reqOptions = {
      reqKakao: state.params.reqKakao,
      reqPhone: state.params.reqPhone,
      reqEmail: state.params.reqEmail
    }
    if (
      proxy.$Util.isEmpty(state.userData) ||
      proxy.$Util.isEmpty(state.userData.authType) ||
      Number(state.userData.authType) < 4
    ) {
      alert(
        '파티모집을 위해 내 정보 수정 > 휴대폰 인증 및 계좌번호 등록을 해주세요.'
      )
      await fnMyInfo()
      return false
    }
    // if (Number(state.userData.authType) < 4) {
    //   alert(
    //     '파티모집시에 수익에 수령 계좌번호가 필요합니다. 내 정보 수정에서 계좌번호를 업데이트 해주세요.'
    //   )
    //   return false
    // }
    if (proxy.$Util.isEmpty(state.params.categoryId)) {
      alert('카테고리를 선택해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.title)) {
      alert('제목을 입력해주세요.')
      return false
    }
    let price = Number(state.params.dailyPrice)
    // TODO 여기 체크
    if (state.params.unlimitedCheckYn === 'N') {
      // 무제한 아닌경우
      if (state.params.usePeriod < 1) {
        alert('서비스 이용기간을 설정해주세요.')
        return false
      }
      if (state.params.acntTransferYn === 'N') {
        price = Number(state.params.dailyPrice) * Number(state.params.usePeriod)
      }
    }
    if (price < 1000) {
      alert('판매가는 1,000원 이상 등록해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.precautions)) {
      alert('파티 소개글 및 주의사항을 입력해주세요.')
      return false
    }
    if (state.params.shareYn === 'N') {
      if (proxy.$Util.isEmpty(state.params.shareId)) {
        if (state.params.autoExtension === 'Y') {
          alert(
            '공유계정 정보를 등록해주세요.\n공유계정이 등록된 파티만 자동연장 설정이 가능합니다.'
          )
        } else {
          alert('공유 계정 아이디를 입력해주세요.')
        }
        return false
      }
      if (proxy.$Util.isEmpty(state.params.sharePw)) {
        if (state.params.autoExtension === 'Y') {
          alert(
            '공유계정 정보를 등록해주세요.\n공유계정이 등록된 파티만 자동연장 설정이 가능합니다.'
          )
        } else {
          alert('공유 계정 패스워드를 입력해주세요.')
        }
        return false
      }
    }
    if (state.params.optionYn === 'Y') {
      if (proxy.$Util.isEmpty(state.params.reqOptionMsg)) {
        alert('요청할 내용을 입력해주세요.')
        return false
      }
    }
    if (
      (state.params.acntTransferYn === 'Y' ||
        state.params.categoryId == '1004' ||
        state.params.categoryId == '1100') &&
      Object.keys(reqOptions).every(key => reqOptions[key] == 0)
    ) {
      alert('구매자 정보요청을 선택해주세요.')
      return false
    }

    return true
  }
  const fnCancel = async () => {
    await router.go(-1)
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  onBeforeUnmount(async () => {
    await dispatch('party/updateHopeDetail', { hopeDetail: {} })
  })
  const fnCheckAutoExtension = () => {
    state.params.autoExtension = state.params.autoExtension === 'N' ? 'Y' : 'N'
    if (state.params.autoExtension === 'Y' && state.params.usePeriod < 7) {
      alert(
        '7일 미만의 파티는 자동 연장이 불가합니다. \n' +
          '파티 일정을 확인해주세요. \n'
      )
      state.params.autoExtension = 'N'
    }
  }
  /** watch **/
  watch(
    () => state.params.usePeriod,
    val => {
      state.params.useDt = proxy
        .$dayjs()
        .add(val - 1, 'day')
        .format('YYYYMMDD')
    }
  )
  watch(
    () => state.params.recruitNum,
    val => {
      if (val === 'acntTransfer') {
        // 계정양도
        state.params.acntTransferYn = 'Y'
        state.params.autoExtension = 'N'
      } else {
        state.params.acntTransferYn = 'N'
        state.params.unlimitedCheckYn = 'N' // 무제한 또는 기간별도 표시
        state.params.optionYn = 'N' // 정보요청
      }
    }
  )
  watch(
    () => state.params.shareYn,
    val => {
      if (val === 'Y') {
        state.params.shareId = ''
        state.params.sharePw = ''
      }
    }
  )
  watch(
    () => state.params.dailyPrice,
    val => {
      if (!proxy.$Util.isEmpty(val) && val.length > 9) {
        state.params.dailyPrice = val.substr(0, 9)
      }
    }
  )
  watch(
    () => state.params.shareId,
    val => {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
      if (reg.exec(val) !== null) {
        state.params.shareId = ''
      }
    }
  )
  watch(
    () => state.params.sharePw,
    val => {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
      if (reg.exec(val) !== null) {
        state.params.sharePw = ''
      }
    }
  )
  watch(
    () => state.params.unlimitedCheckYn,
    val => {
      if (val === 'Y') {
        state.params.usePeriod = ''
      }
    }
  )
  watch(
    () => state.params.autoExtension,
    val => {
      if (val === 'Y') {
        state.params.shareYn = 'N'
      }
    }
  )

  const maskingUsePeriodNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 3) {
      e.target.value = e.target.value.substr(0, 3)
      state.params.usePeriod = e.target.value.substr(0, 3)
    } else {
      state.params.usePeriod = e.target.value
    }
  }

  const maskingDailyPriceNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 9) {
      e.target.value = e.target.value.substr(0, 9)
      state.params.dailyPrice = e.target.value.substr(0, 9)
    } else {
      state.params.dailyPrice = e.target.value
    }
  }

  const init = async () => {
    await fnCategoryList()
    if (!proxy.$Util.isEmpty(state.hopeDetail)) {
      state.params.categoryId = state.hopeDetail.categoryId
      state.params.title = state.hopeDetail.title
      state.params.usePeriod = state.hopeDetail.usePeriod
      state.params.dailyPrice = Number(
        (state.hopeDetail.minPrice / state.hopeDetail.usePeriod).toFixed(0)
      )
    }
    if (proxy.$Util.isMobile()) {
      const isNotShowInfo = getters['party/getIsNotShowInfo']
      const isShowById = proxy.$_.find(isNotShowInfo, {
        id: state.userData.userId || state.userData.snsId
      })
      if (
        proxy.$Util.isEmpty(isShowById) ||
        (!proxy.$Util.isEmpty(isShowById) &&
          isShowById.value === proxy.$ConstCode.BOOLEAN_VALUE.FALSE)
      ) {
        const params = {
          component: proxy.$modalNames.PARTY_INFO,
          data: {},
          callback: () => {}
        }
        await fnModal(params)
      }
    }
  }
  init()
  return {
    ...toRefs(state),
    fnCategoryList,
    fnSave,
    fnCancel,
    fnCallback,
    fnCheckAutoExtension,
    maskingUsePeriodNumber,
    maskingDailyPriceNumber
  }
}

// https://localhost:8081/party/done?productNo=861&poid=WIZZNEY20210917QsbbH4-861-20211223083609-1000-1000&productType=PARTY
